header{
    .ast-flex{
        flex-wrap:wrap;
        @media only screen and (min-width: 600px) {
            display:flex;
        }
    }
    .custom-logo-link{
        display: flex;
        align-items: center;
        font-size: 24px;
        gap: 15px;
        text-transform: uppercase;
        color: #fff;
        @media only screen and (max-width: 600px) {
          font-size: 0;
        }
      }
      .ast-main-header-bar-alignment{
        @media only screen and (max-width: 600px) {
            position: absolute;
            top: 70px;
            &:not(.openNav){
                display: none;
            }
        }
    }
    .ast-button-mobile{
        button{
            background-color: #fff;
        }
        @media only screen and (min-width: 600px) {
            display: none;
        }
    }
    .ast-builder-menu-1{
        @media only screen and (max-width: 600px) {
            justify-content: flex-end;
        }
      }
}