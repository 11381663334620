
  
  .section-header {
    text-align: center;
    margin: 0 auto;
    padding: 40px 0;
    font: 300 60px 'Oswald', sans-serif;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 6px;
  }
  
  .contact-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 0 auto;
    padding: 20px;
    position: relative;
    max-width: 840px;
  }
  
  /* Left contact page */
  .form-horizontal {
    /*float: left;*/
    max-width: 400px;
    font-family: 'Lato';
    font-weight: 400;
  }
  
  .form-control, 
  textarea {
    max-width: 400px;
    background-color: #000;
    color: #fff;
    letter-spacing: 1px;
  }
  
  .send-button {
    margin-top: 15px;
    height: 34px;
    width: 400px;
    overflow: hidden;
    transition: all .2s ease-in-out;
  }
  
  .alt-send-button {
    width: 400px;
    height: 34px;
    transition: all .2s ease-in-out;
  }

  .alt-send-button{
    img{
      margin-top: 5px;
    }
  }
  
  .send-text {
    display: block;
    margin-top: 12px;
    font: 700 12px 'Lato', sans-serif;
    letter-spacing: 2px;
  }
  
  .send-button:hover .alt-send-button {
    transform: translate3d(0px, -29px, 0px);
  }
  
  /* Begin Right Contact Page */
  .direct-contact-container {
    max-width: 400px;
    min-width: 340px;
  }
  
  /* Location, Phone, Email Section */
  .contact-list {
    list-style-type: none;
    padding-right: 20px;
  }
  
  .list-item {
    line-height: 4;
    text-align: left;
    display: flex;
    padding: 20px 0;
  }
  
  .contact-text {
    font: 300 18px 'Lato', sans-serif;
    letter-spacing: 1.9px;
  }
  
  .contact-text {
    margin-left: 62px;
  }
  
  .contact-text a {
    color: #333;
    text-decoration: none;
    transition-duration: 0.2s;
  }
  
  .contact-text a:hover {
    color: #ef0027;
    text-decoration: none;
  }
  
  
  /* Social Media Icons */
  .social-media-list {
    position: relative;
    font-size: 22px;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    padding: 0;
  }
  
  .social-media-list li a {
    color: #fff;
  }
  
  .social-media-list li {
    position: relative; 
    display: inline-block;
    height: 50px;
    width: 50px;
    margin: 10px 3px;
    line-height: 50px;
    border-radius: 50%;
    color: #fff;
    background-color: rgb(27,27,27);
    cursor: pointer; 
    transition: all .2s ease-in-out;
  }
  
  .social-media-list li:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 60px;
    border-radius: 50%;
    opacity: 0;
    box-shadow: 0 0 0 1px #fff;
    transition: all .2s ease-in-out;
  }
  
  .social-media-list li:hover {
    background-color: #fff; 
  }
  
  .social-media-list li:not(:hover) img {
    filter: invert(1); 
  }

  .social-media-list li img {
    width: 25px;
    height: 25px;
  }

  .social-media-list li:hover:after {
    opacity: 1;  
    transform: scale(1.12);
    transition-timing-function: cubic-bezier(0.37,0.74,0.15,1.65);
  }
  
  .social-media-list li:hover a {
    color: #000;
  }
  
  .copyright {
    font: 200 14px 'Oswald', sans-serif;
    color: #555;
    letter-spacing: 1px;
    text-align: center;
  }
  
  hr {
    border-color: rgba(255,255,255,.6);
  }
  
  /* Begin Media Queries*/
  @media screen and (max-width: 850px) {
    .contact-wrapper {
      display: flex;
      flex-direction: column;
    }
    .direct-contact-container, .form-horizontal {
      margin: 0 auto;
    }  
    
    .direct-contact-container {
      margin-top: 60px;
      max-width: 300px;
    }    
    .social-media-list li {
      height: 60px;
      width: 60px;
      line-height: 60px;
    }
    .social-media-list li:after {
      width: 60px;
      height: 60px;
      line-height: 60px;
    }
  }
  
  @media screen and (max-width: 569px) {
  
    .direct-contact-container, .form-wrapper {
      float: none;
      margin: 0 auto;
    }  
    .form-control, textarea {
      
      margin: 0 auto;
    }
   
    
    .name, .email, textarea {
      width: 280px;
    } 
    
    .direct-contact-container {
      margin-top: 60px;
      max-width: 280px;
    }  
    .social-media-list {
      left: 0;
    }
    .social-media-list li {
      height: 55px;
      width: 55px;
      line-height: 55px;
      font-size: 2rem;
    }
    .social-media-list li:after {
      width: 55px;
      height: 55px;
      line-height: 55px;
    }
    
  }
  
  @media screen and (max-width: 410px) {
    .send-button {
      width: 99%;
    }
  }