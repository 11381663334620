.home-banner-section { 
    width: 100%; 
    height: 100%; 
    background-position: 0px 0px;
    animation: animatedBackground 100s linear infinite;
    -moz-animation: animatedBackground 100s linear infinite;
    -webkit-animation: animatedBackground 100s linear infinite;
    -ms-animation: animatedBackground 100s linear infinite;
    -o-animation: animatedBackground 100s linear infinite;
}

@keyframes animatedBackground {
    0% { background-position: 0 0; }
    100% { background-position: -3000px 0; }
}
@-moz-keyframes animatedBackground {
    0% { background-position: 0 0; }
    100% { background-position: -3000px 0; }
}
@-webkit-keyframes animatedBackground {
    0% { background-position: 0 0; }
    100% { background-position: -3000px 0; }
}
@-ms-keyframes animatedBackground {
    0% { background-position: 0 0; }
    100% { background-position: -3000px 0; }
}
@-o-keyframes animatedBackground {
    0% { background-position: 0 0; }
    100% { background-position: -3000px 0; }
}

.banner-img{
    background-repeat: no-repeat;
    background-size: contain;
    &.elementor-widget-wrap{
        display: block !important;
        // background-color: #ffffff;
        // box-shadow: inset 0 0 0 16px #212121;
        // border: 1px #717171 solid;

        background-color: #e6e6e6;
        box-shadow: inset 0 0 0 19px #212121, inset 0 0 20px 16px #212121;
        border: 1px #717171 solid;

        border-left-color: #4f4f4f;
        border-bottom-color: #4f4f4f;
        height: 444px;
    }
}
