


.home-banner-section{
  .slick-list{
    height: 357px;
  }
}

.slick-slider{
  
  .slick-track{
    height: 100%;
  }
  .slick-prev, .slick-next{
    z-index: 9;
  }
  
  .slick-dots{
    font-size: 0;
    li{
      margin: 0;
      button{
        height: 24px;
      }
      &.slick-active button{
        &:before{
          color: var(--ast-global-color-0);
        }
      }
    }
  }

  .slick-slide{
    position: relative;
    height: 100%;
    &>div{
      height: 100%;
      &>div{
        height: 100%;
        &>img{
          height: 100%;
        }
      }
    }
    .banner_content{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      left: 0;
      justify-content: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      .heading{
        font-size: 50px;
        margin: 0;
        font-weight: 400;
      }
      .description{
        font-size: 20px;
        font-weight: 300;
      }
    }
    img{
    width: 100%;
    }
  }
}

.frame-img{
  background-color: #e6e6e6;
  box-shadow:inset 0 0 5px 0px rgba(255, 255, 255, 0.7490196078), inset 0 0 0 15px #212121, inset 0 0 29px 0px #212121, -25px 25px 40px 0 rgba(0, 0, 0, 0.15);
  border: 1px #717171 solid;
  padding: 37px;
}
.thumb-frame-img{
  background-color: #e6e6e6;
  box-shadow: inset 0 0 3px 0px rgba(255, 255, 255, 0.7490196078), inset 0 0 0 7px #212121, inset 0 0 17px 0px #212121;
  border: 1px #717171 solid;
  padding: 18px;
}